<template>
  <div class="grid gap-x-3 gap-y-8 grid-cols-2">
    <div class="flex flex-row gap-x-2 items-start">
      <div id="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            stroke="#6D6D6D"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12.667 2.667H3.333C2.597 2.667 2 3.264 2 4v9.333c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.736-.597-1.333-1.333-1.333zM10.666 1.333V4M5.333 1.333V4M2 6.667h12"
          />
        </svg>
      </div>
      <div class="flex flex-col">
        <div id="title" class="text-base font-EffraM text-labelGray">
          Date
        </div>
        <div class="text-lg text-black font-EffraR">
          {{ event.startAt | moment("DD MMM YY") }}
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-x-2 items-start">
      <div id="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            stroke="#6D6D6D"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 14.667c3.682 0 6.667-2.985 6.667-6.667S11.682 1.333 8 1.333 1.333 4.318 1.333 8 4.319 14.667 8 14.667z"
          />
          <path
            stroke="#6D6D6D"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 4v4l2.667 1.333"
          />
        </svg>
      </div>
      <div class="flex flex-col">
        <div id="title" class="text-base font-EffraM text-labelGray">
          Heure
        </div>
        <div class="text-lg text-black font-EffraR">
          {{ event.startAt | moment("HH:mm") }}
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-x-2 items-start">
      <div id="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            stroke="#6D6D6D"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13.333 14v-1.333c0-.708-.28-1.386-.78-1.886-.501-.5-1.18-.781-1.887-.781H5.333c-.707 0-1.385.281-1.885.781s-.781 1.178-.781 1.886V14M8 7.333c1.473 0 2.667-1.194 2.667-2.666C10.667 3.194 9.473 2 8 2 6.527 2 5.333 3.194 5.333 4.667c0 1.472 1.194 2.666 2.667 2.666z"
          />
        </svg>
      </div>
      <div class="flex flex-col">
        <div id="title" class="text-base font-EffraM text-labelGray">
          Praticien
        </div>
        <div class="text-lg text-black font-EffraR">
          {{
            `${event.owner.title}. ${event.owner.firstName} ${event.owner.lastName}`
          }}
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-x-2 items-start">
      <div id="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            fill="#6D6D6D"
            stroke="#6D6D6D"
            stroke-width=".5"
            d="M3.889 1v1.474H2.444V5.42c0 1.628 1.293 2.947 2.89 2.947 1.595 0 2.888-1.319 2.888-2.947V2.474H6.778V1h2.166c.4 0 .723.33.723.737V5.42c0 2.19-1.562 4.008-3.611 4.36v1.166c0 1.425 1.131 2.58 2.527 2.58 1.082 0 2.005-.693 2.366-1.668-.757-.344-1.282-1.118-1.282-2.017 0-1.22.97-2.21 2.166-2.21 1.197 0 2.167.99 2.167 2.21 0 1.01-.664 1.862-1.572 2.126C11.985 13.712 10.431 15 8.583 15c-2.194 0-3.972-1.814-3.972-4.053V9.781C2.561 9.431 1 7.611 1 5.421V1.737C1 1.33 1.324 1 1.722 1H3.89zm7.944 8.105c-.398 0-.722.33-.722.737s.324.737.722.737c.399 0 .723-.33.723-.737s-.324-.737-.723-.737z"
          />
        </svg>
      </div>
      <div class="flex flex-col">
        <div id="title" class="text-base font-EffraM text-labelGray">
          Spécialité
        </div>
        <div class="text-lg text-black font-EffraR">
          {{
            event.owner.landingPage && event.owner.landingPage.specialities[0]
              ? `${event.owner.landingPage.specialities[0].name}`
              : `--`
          }}
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-x-2 items-start">
      <div id="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            stroke="#767676"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14.666 11.28v2c.001.186-.037.37-.111.54-.075.17-.184.322-.32.448-.137.125-.299.221-.475.28-.176.06-.362.082-.547.065-2.051-.223-4.022-.924-5.753-2.046-1.611-1.024-2.977-2.39-4-4-1.127-1.74-1.828-3.72-2.047-5.78-.017-.185.005-.37.064-.546.06-.175.155-.337.28-.473.124-.137.276-.246.445-.321.17-.075.353-.114.538-.114h2c.323-.003.637.112.882.323.246.21.406.504.451.824.085.64.241 1.268.467 1.873.09.239.109.498.056.748-.053.249-.177.478-.356.659l-.847.847c.95 1.669 2.331 3.05 4 4l.847-.847c.181-.18.41-.303.66-.356.248-.053.508-.034.746.056.605.226 1.234.382 1.874.467.324.045.62.209.83.458.212.25.325.568.316.895z"
          />
        </svg>
      </div>
      <div class="flex flex-col">
        <div id="title" class="text-base font-EffraM text-labelGray">
          Téléphone
        </div>
        <div class="text-lg text-black font-EffraR">
          {{ event.owner.fix || "--" }}
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-x-2 items-start">
      <div id="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 1.333a5.333 5.333 0 0 0-5.334 5.334c0 2.065 1.344 4.07 2.818 5.624A19.622 19.622 0 0 0 8 14.515a19.62 19.62 0 0 0 2.516-2.224c1.473-1.555 2.817-3.559 2.817-5.624A5.333 5.333 0 0 0 8 1.333zm0 14-.37.555-.002-.002-.005-.002-.015-.01a7.07 7.07 0 0 1-.256-.181 20.934 20.934 0 0 1-2.836-2.484c-1.527-1.612-3.183-3.941-3.183-6.542a6.667 6.667 0 0 1 13.333 0c0 2.601-1.656 4.93-3.182 6.542a20.935 20.935 0 0 1-3.037 2.626 7.07 7.07 0 0 1-.056.038l-.015.01-.004.004H8.37L8 15.333zm0 0 .37.555a.667.667 0 0 1-.74 0l.37-.555z"
            fill="#6D6D6D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 5.333A1.333 1.333 0 1 0 8 8a1.333 1.333 0 0 0 0-2.667zM5.333 6.667a2.667 2.667 0 1 1 5.333 0 2.667 2.667 0 0 1-5.333 0z"
            fill="#6D6D6D"
          />
        </svg>
      </div>
      <div class="flex flex-col">
        <div id="title" class="text-base font-EffraM text-labelGray">
          Adresse
        </div>
        <div class="text-lg text-black font-EffraR">
          22 Avril 2021
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-x-2 items-start">
      <div id="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <g clip-path="url(#clip0)">
            <path
              stroke="#6D6D6D"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4.667 4.667h.006m9.054 4.273l-4.78 4.78c-.124.124-.271.222-.433.29-.162.066-.335.101-.51.101-.176 0-.35-.035-.511-.102-.162-.067-.309-.165-.433-.289L1.334 8V1.333H8l5.727 5.727c.248.25.388.588.388.94s-.14.69-.388.94z"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#fff" d="M0 0H16V16H0z" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="flex flex-col">
        <div id="title" class="text-base font-EffraM text-labelGray">
          Tarif
        </div>
        <div class="text-lg text-black font-EffraR">
          {{ event.initPrice }} DH
        </div>
      </div>
    </div>
    <div class="flex flex-row gap-x-2 items-start">
      <div id="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <g clip-path="url(#clip0)">
            <path
              stroke="#6D6D6D"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4.667 4.667h.006m9.054 4.273l-4.78 4.78c-.124.124-.271.222-.433.29-.162.066-.335.101-.51.101-.176 0-.35-.035-.511-.102-.162-.067-.309-.165-.433-.289L1.334 8V1.333H8l5.727 5.727c.248.25.388.588.388.94s-.14.69-.388.94z"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#fff" d="M0 0H16V16H0z" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="flex flex-col">
        <div id="title" class="text-base font-EffraM text-labelGray">
          Motif
        </div>
        <div class="text-lg text-black font-EffraR">
          {{ event.category.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  }
};
</script>